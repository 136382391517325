import * as React from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import tenkiu from '../images/360/01-mitsubishi-orange.png'

import phev from '../images/l200_logo.png'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

const item = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    px: 5,
};
export default function ProductHero(props) {

    const isMobile = () => {
        return (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/BlackBerry/i)
        );
      };

    console.log("PROSPS", props.typeDialog)
    return (
        <Grid container direction="row"
            justifyContent='center'
            alignItems="center"
            sx={{

                background:
                    " linear-gradient(48deg, rgba(37,37,37,1) 0%, rgba(21,21,21,0.989233193277311) 8%, rgba(21,21,21,1) 52%, rgba(21,21,21,1) 66%, rgba(61,60,63,0.9752275910364145) 97%)"
            }}
        >
            <Grid item xs={12} sm={4} md={5} sx={{ marginLeft: "45px", marginRight: "45px", marginTop: isMobile() ? "32px" : "0px" }}  >
                {props.typeDialog == "aparta" ? (
                    <><Typography style={{
                        fontFamily: "mmcoffice",
                        marginTop: "8%",
                        fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                    }} align="left" component="h1" color="white">
                        Estás a un paso de apartar tu
                    </Typography>
                    <Typography style={{
                        fontFamily: "mmcoffice",
                        marginTop: "0",
                        fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                    }} align="left" component="h1" color="white">
                        Nueva Mitsubishi L200 2025 GSR. 
                    </Typography><Typography style={{
                        fontFamily: "mmcregular",
                        fontSize: "clamp(0.4rem, 4vw, 1.2rem)",
                    }} align="left" component="h1" color="white">
                            Pronto recibirás un correo para continuar tu proceso.
                        </Typography>
                        {/* <img
                            src={phev}
                            alt="increase priority"
                            style={{
                                marginBottom: "25px",
                                marginTop: "32px",
                                maxWidth: '60%',
                                marginLeft: "-10px"
                            }}

                        /> */}
                    </>

                ) : (

                    <>
                    {/* <Typography style={{
                        fontFamily: "mmcoffice",
                        fontSize: "clamp(0.4rem, 4vw, 1.8rem)",
                    }} align="left" component="h1" color="white">
                        Muy pronto te enviaremos más información


                    </Typography> */}
                    <Typography style={{
                        fontFamily: "mmcregular",
                        fontSize: "clamp(1.4rem, 4vw, 2.2rem)",
                    }} align="left" component="h1" color="white">
                            Muy pronto te enviaremos la información y noticias para que estrenes tu Nueva ​
                        </Typography>
                    <Typography style={{
                        fontFamily: "mmcregular",
                        fontSize: "clamp(1.4rem, 4vw, 2.2rem)",
                    }} align="left" component="h1" color="white">
                            Mitsubishi L200 2025 GSR.
                        </Typography></>
                )}
                
            <Button
                  
                  variant="contained"
                  size= "medium"
                  color="primary"
                  style={{
                    width: '250px',
                    height: '65px',
                    background: "#cf0303",
                    borderRadius: "10px",
                    fontFamily: "mmcregular",
                    fontSize: "clamp(1rem, 4vw, 1rem)",
                    letterSpacing: "0.1em",
                    lineHeight: "1em",
                    marginBottom:"32px",
                    marginTop: "32px",
                    display:'block'
                  }}
                >
                  <Link sx={{ color: "white" }}
                href={`${process.env.REACT_APP_URL}`}>INICIO</Link>
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={6}>

                <img
                    src={tenkiu}
                    alt="increase priority"
                    style={{
                        marginBottom: "90px",
                        marginTop: "15%",

                        maxWidth: '100%',
                    }}

                />
            </Grid>

        </Grid>
    );
}
